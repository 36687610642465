import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import {
  AmazonIcon,
  EbayIcon,
  FacebookIcon,
  InstagramIcon,
} from '../components/ServiceIcons'
import Layout from '../layouts/index'

class Services extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black">
              <div className="intro">
                <div className="small-12 cell">
                  <header className="text-center">
                    <h1>
                      <div className="line first last">
                        eCommerce solutions for Growth
                      </div>
                    </h1>
                  </header>
                </div>
              </div>
            </div>
          </div>

          <div className="callout magento">
            <div className="grid-x">
              <div className="cell text-center small-10 small-offset-1 medium-8 medium-offset-2">
                <h3>eCommerce Consulting Services</h3>
                <p>
                  Whether you have an e-shop or looking into building one - we
                  will help you.
                </p>
                <p>
                  We have vast experience in launching e-shops on{' '}
                  <b>Magento Community Edition</b>, <b>Magento Commerce</b> and
                  other platforms
                </p>
              </div>
            </div>
          </div>

          <div className="callout white">
            <div className="grid-x">
              <div className="cell text-center small-10 small-offset-1 medium-8 medium-offset-2">
                <h3>Let's Grow Together</h3>
                <p className="big-text">
                  We provide eCommerce Solutions for growth that leverage the
                  best open source technology available.
                </p>
                <p>
                  We specialize in building stores based on Magento 2 eCommerce
                  Platform and eCommerce marketing solutions. We are here to
                  help your business utilize the newest web technologies and to
                  increase efficiency.
                </p>
              </div>
            </div>
          </div>

          <div className="callout magento">
            <div className="grid-x">
              <div className="text-center small-10 small-offset-1 medium-8 medium-offset-2">
                <img className="the-logo" src="/magento-logo.svg" />
                <h3>Magento 2 Platform</h3>
                <p>
                  We will help you to grow your business on top of the best
                  eCommerce platform.
                </p>
              </div>
            </div>
          </div>

          <a name="integrations" />

          <div className="callout blue">
            <div className="grid-x">
              <div className="text-center small-10 small-offset-1 medium-8 medium-offset-2">
                <h3>Omni-channel Customer Experiences</h3>

                <div className="integrations grid-x">
                  <div className="cell text-center small-6 medium-3 gray-box">
                    <div className="wrap">
                      <AmazonIcon />
                      Amazon
                    </div>
                  </div>
                  <div className="cell text-center small-6 medium-3 gray-box">
                    <div className="wrap">
                      <EbayIcon />
                      eBay
                    </div>
                  </div>
                  <div className="cell text-center small-6 medium-3 gray-box">
                    <div className="wrap">
                      <FacebookIcon />
                      Facebook
                    </div>
                  </div>
                  <div className="cell text-center small-6 medium-3 gray-box">
                    <div className="wrap">
                      <InstagramIcon />
                      Instagram
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a name="migration" />

          <div className="callout white">
            <div className="grid-x">
              <div className="text-center small-10 small-offset-1 medium-8 medium-offset-2">
                <h3>Migration from old platforms</h3>

                <p className="big-text">
                  We will be happy to help you migrate your old website to a new
                  powerful and mobile-ready platform.
                </p>

                <p>
                  Let us update your website to make sure your customers are
                  happy an able to access your website from their favourite
                  devices.
                  <br />
                  We will take care of the SEO in the process of migration so
                  you won't lose these hard-earned search engine rankings.
                </p>

                <br />

                <p>
                  <a className="button hollow large" href="/contact">
                    Get a Quote
                  </a>
                </p>
              </div>
            </div>
          </div>

          <a name="support" />

          <div className="callout blue">
            <div className="grid-x">
              <div className="text-center small-10 small-offset-1 medium-6 medium-offset-3">
                <h5>
                  <strong>
                    Have a project you are interested in discussing with us?
                    <br />
                    Drop us a line, we'd love to talk!
                  </strong>
                </h5>
                <a className="button hollow large" href="/contact">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Services

export const pageQuery = graphql`
  query ServicesQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
