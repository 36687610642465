import React from 'react'

export const AmazonIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' version='1'
         viewBox='0 0 971.68109 195.66141' id='svg1936' className="svg">
        <g transform='translate(151.616 -642.796)' id='layer1'>
            <g transform='translate(7.082 7.081)' id='g1967' fillRule='evenodd'>
                <path
                    d='m 222.02642,784.73404 c -34.99948,25.79739 -85.72909,39.56123 -129.406339,39.56123 -61.24255,0 -116.37656,-22.65135 -158.08757,-60.32496 -3.2771,-2.96252 -0.34083,-6.9999 3.59171,-4.69283 45.01431,26.19064 100.67269,41.94697 158.16623,41.94697 38.774689,0 81.429499,-8.02237 120.649899,-24.67006 5.92501,-2.51683 10.87999,3.88009 5.08607,8.17965'
                    id='path8' fill='#f90'/>
                <path
                    d='m 236.57678,768.08635 c -4.45688,-5.71527 -29.57261,-2.70033 -40.84585,-1.36327 -3.43442,0.41947 -3.95874,-2.56925 -0.86517,-4.71905 20.00346,-14.07844 52.82696,-10.01483 56.65462,-5.2958 3.82764,4.74526 -0.99624,37.64741 -19.79373,53.35128 -2.88385,2.41195 -5.63662,1.12734 -4.35198,-2.07113 4.2209,-10.53917 13.68519,-34.16054 9.20211,-39.90203'
                    id='path10' fill='#f90'/>
                <path
                    d='m 196.51744,662.61598 0,-13.68519 c 0,-2.07113 1.57301,-3.46062 3.46062,-3.46062 l 61.26875,0 c 1.96628,0 3.53929,1.41571 3.53929,3.46062 l 0,11.71893 c -0.0262,1.96626 -1.67788,4.53551 -4.61418,8.59912 l -31.74859,45.32893 c 11.79759,-0.28837 24.25059,1.46814 34.94706,7.49802 2.41195,1.36327 3.06737,3.35575 3.25089,5.32203 l 0,14.60278 c 0,1.99248 -2.20222,4.32576 -4.5093,3.1198 -18.84992,-9.88376 -43.887,-10.95865 -64.72939,0.10487 -2.12356,1.15354 -4.35199,-1.15354 -4.35199,-3.14602 l 0,-13.86871 c 0,-2.22843 0.0262,-6.02989 2.25463,-9.41186 l 36.78224,-52.74829 -32.01076,0 c -1.96626,0 -3.53927,-1.38948 -3.53927,-3.43441'
                    id='path12' fill='#221f1f'/>
                <path
                    d='m -26.981169,748.00424 -18.64017,0 c -1.78273,-0.13107 -3.19845,-1.46813 -3.32954,-3.17224 l 0,-95.66524 c 0,-1.91383 1.59923,-3.43442 3.59171,-3.43442 l 17.38176,0 c 1.80898,0.0786 3.25089,1.46814 3.38199,3.19845 l 0,12.50545 0.34082,0 c 4.53551,-12.08598 13.05597,-17.7226 24.53896043,-17.7226 11.66648957,0 18.95476957,5.63662 24.19813957,17.7226 4.5093,-12.08598 14.76008,-17.7226 25.74495,-17.7226 7.81262,0 16.35931,3.22467 21.57646,10.46052 5.89879,8.04857 4.69281,19.74128 4.69281,29.99208 l -0.02621,60.37739 c 0,1.91383 -1.59923,3.46061 -3.59171,3.46061 l -18.61397,0 c -1.86138,-0.13107 -3.35574,-1.62543 -3.35574,-3.46061 l 0,-50.70338 c 0,-4.03739 0.36702,-14.10466 -0.52434,-17.93233 -1.38949,-6.42311 -5.55797,-8.23209 -10.95865,-8.23209 -4.5093,0 -9.22833,3.01494 -11.14216,7.83885 -1.91383,4.8239 -1.73031,12.89867 -1.73031,18.32557 l 0,50.70338 c 0,1.91383 -1.59923,3.46061 -3.59171,3.46061 l -18.6139496,0 c -1.88761,-0.13107 -3.35575997,-1.62543 -3.35575997,-3.46061 l -0.02621,-50.70338 c 0,-10.67025 1.75650997,-26.37415 -11.48298043,-26.37415 -13.39682,0 -12.87248,15.31063 -12.87248,26.37415 l 0,50.70338 c 0,1.91383 -1.59923,3.46061 -3.59171,3.46061'
                    id='path14' fill='#221f1f'/>
                <path
                    d='m 317.53439,643.71364 c 27.65877,0 42.62858,23.75246 42.62858,53.95427 0,29.17934 -16.54284,52.32881 -42.62858,52.32881 -27.16066,0 -41.94697,-23.75246 -41.94697,-53.35127 0,-29.78234 14.96983,-52.93181 41.94697,-52.93181 m 0.15729,19.53156 c -13.73761,0 -14.60278,18.71881 -14.60278,30.38532 0,11.69271 -0.18352,36.65114 14.44549,36.65114 14.44548,0 15.12712,-20.13452 15.12712,-32.40403 0,-8.07477 -0.34082,-17.72257 -2.779,-25.3779 -2.09735,-6.65906 -6.26581,-9.25453 -12.19083,-9.25453'
                    id='path16' fill='#221f1f'/>
                <path
                    d='m 396.02762,748.00424 -18.56152,0 c -1.86141,-0.13107 -3.35577,-1.62543 -3.35577,-3.46061 l -0.0262,-95.69149 c 0.1573,-1.75653 1.7041,-3.1198 3.59171,-3.1198 l 17.27691,0 c 1.62543,0.0786 2.96249,1.17976 3.32954,2.67412 l 0,14.62899 0.3408,0 c 5.21717,-13.0822 12.53165,-19.32181 25.40412,-19.32181 8.36317,0 16.51662,3.01494 21.75999,11.27324 4.87633,7.65532 4.87633,20.5278 4.87633,29.78233 l 0,60.22011 c -0.20973,1.67786 -1.75653,3.01492 -3.59169,3.01492 l -18.69262,0 c -1.70411,-0.13107 -3.11982,-1.38948 -3.30332,-3.01492 l 0,-51.96179 c 0,-10.46052 1.20597,-25.77117 -11.66651,-25.77117 -4.5355,0 -8.70399,3.04117 -10.77512,7.65532 -2.62167,5.84637 -2.96249,11.66651 -2.96249,18.11585 l 0,51.5161 c -0.0262,1.91383 -1.65166,3.46061 -3.64414,3.46061'
                    id='path18' fill='#221f1f'/>
                <path
                    d='m 462.33002,740.40137 c 0,-4.82391 4.11603,-8.70399 9.17588,-8.70399 5.05985,0 9.17589,3.88008 9.17589,8.70399 0,4.79767 -4.11604,8.73021 -9.17589,8.73021 -5.05985,0 -9.17588,-3.93254 -9.17588,-8.73021'
                    id='path20' fill='#221f1f'/>
                <path
                    d='m 659.82149,748.03047 c -1.94004,-0.0787 -3.46062,-1.57301 -3.46062,-3.46062 l 0,-95.66526 c 0.10487,-1.70411 1.54679,-3.04117 3.32954,-3.14602 l 6.84259,0 c 1.88762,0 3.40819,1.36326 3.56549,3.14602 l 0,13.94736 c 4.87634,-11.06352 13.94737,-19.71508 25.40413,-19.71508 l 0.70785,0 0.68164,0 c 12.16461,0 21.05212,8.96616 24.35545,21.99593 5.16471,-12.87247 14.86495,-21.99593 27.65877,-21.99593 l 0.65542,0 0.76029,0 c 9.04481,0 17.7488,5.82014 22.2581,14.68143 4.35199,8.46804 4.19469,19.74128 4.19469,29.20556 l -0.0262,57.54599 c 0.0262,1.83516 -1.46814,3.32952 -3.32953,3.46062 l -8.17966,0 c -1.78275,-0.0787 -3.22467,-1.33706 -3.46062,-2.98872 l 0,-58.01789 c 0,-6.84258 0.34082,-14.10466 -2.43817,-20.34427 -2.83142,-6.37069 -8.25831,-10.35565 -14.07844,-10.64402 -6.50178,0.31459 -12.47922,5.05985 -16.35932,11.45676 -5.03363,8.25829 -4.85011,15.70387 -4.85011,25.35168 l 0,52.25016 c -0.23595,1.57301 -1.62545,2.8052 -3.32954,2.9363 l -8.12722,0 c -1.94005,-0.0787 -3.48684,-1.57301 -3.48684,-3.46062 l -0.0524,-61.37365 c 0,-5.63662 -0.34082,-12.26948 -2.93628,-17.32933 -3.01494,-5.76769 -8.41561,-9.54293 -14.07845,-9.8313 -5.87257,0.34082 -11.79758,4.8239 -15.31063,10.04105 -4.53552,6.65906 -5.40067,14.89115 -5.40067,23.3592 l 0,55.13403 c 0,1.83516 -1.49436,3.32952 -3.35576,3.46062 l -8.15344,0'
                    id='path22' fill='#221f1f'/>
                <path
                    d='m 603.66501,750.02295 c -26.45279,0 -38.30281,-26.97714 -38.30281,-53.95427 0,-28.36663 13.92115,-52.93181 40.55746,-52.93181 l 0.68164,0 0.73407,0 c 25.90224,0 38.80093,26.16442 38.80093,53.14155 0,28.57635 -14.28818,53.74453 -41.08179,53.74453 l -0.68164,0 -0.70786,0 m 1.94005,-13.0822 c 8.70399,-0.2884 15.5728,-5.68907 19.63642,-14.68143 3.64414,-8.0748 4.35199,-17.32935 4.35199,-26.19064 0,-9.64781 -1.04867,-19.71506 -5.58419,-27.97338 -4.06361,-7.20963 -11.03729,-11.79756 -18.43044,-12.05973 -8.23209,0.28837 -15.59902,5.87256 -19.29559,14.47168 -3.32954,7.4456 -4.352,17.32933 -4.352,25.56143 0,9.25455 1.20597,19.95102 5.03363,27.9996 3.7228,7.6291 10.87999,12.58407 18.64018,12.87247'
                    id='path24' fill='#221f1f'/>
                <path
                    d='m 521.0033,736.52128 c 11.87624,-0.36705 18.11584,-9.88375 20.68509,-22.20568 0.52434,-1.54679 1.7041,-2.72655 3.43441,-2.72655 l 7.83884,-0.0262 c 1.86139,0.0786 3.56549,1.49436 3.40819,3.22469 -3.61793,20.99967 -16.28066,35.23543 -34.31785,35.23543 l -0.73407,0 -0.68164,0 c -26.26929,0 -37.59495,-26.37415 -37.59495,-53.14156 0,-26.55764 11.48298,-53.74452 37.75225,-53.74452 l 0.70785,0 0.70786,0 c 18.24692,0 31.25048,14.05223 34.0819,35.05193 0,1.57301 -1.46815,2.93627 -3.19846,3.11979 l -8.20587,-0.10487 c -1.73031,-0.23595 -2.85764,-1.70408 -3.11981,-3.35574 -1.96626,-11.71894 -8.70399,-21.05212 -19.9248,-21.41917 -17.85368,0.57677 -22.93973,22.54648 -22.93973,39.45634 0,16.28067 4.24713,40.05936 22.10079,40.63613'
                    id='path26' fill='#221f1f'/>
                <path
                    d='m 147.67545,702.30829 c 0,7.26206 0.18352,13.31815 -3.48684,19.7675 -2.9625,5.24338 -7.68153,8.46805 -12.89868,8.46805 -7.1572,0 -11.35189,-5.45312 -11.35189,-13.50169 0,-15.8874 14.23574,-18.77125 27.73741,-18.77125 l 0,4.03739 m 18.79748,45.43378 c -1.23218,1.10111 -3.01494,1.17976 -4.40443,0.4457 -6.18718,-5.1385 -7.3145,-7.52423 -10.69647,-12.42678 -10.22457,10.4343 -17.48665,13.55409 -30.72615,13.55409 -15.70389,0 -27.894719,-9.67401 -27.894719,-29.04824 0,-15.12713 8.179669,-25.43035 19.872379,-30.46398 10.1197,-4.45688 24.25056,-5.24337 35.05191,-6.47556 l 0,-2.41195 c 0,-4.43066 0.34082,-9.67403 -2.28086,-13.50167 -2.25465,-3.43442 -6.60666,-4.85013 -10.46052,-4.85013 -7.10478,0 -13.42302,3.64414 -14.96983,11.19459 -0.3146,1.67789 -1.54678,3.32955 -3.25089,3.4082 l -18.063399,-1.94006 c -1.52058,-0.3408 -3.22467,-1.57301 -2.77898,-3.90629 4.142259,-21.91728 23.962199,-28.52394 41.684769,-28.52394 9.07104,0 20.92105,2.41195 28.07825,9.28076 9.07102,8.46804 8.20587,19.7675 8.20587,32.06321 l 0,29.04826 c 0,8.73022 3.61792,12.55786 7.02611,17.27691 1.17977,1.67786 1.44194,3.69656 -0.0787,4.95497 -3.80144,3.17225 -10.56539,9.07104 -14.28818,12.37436 l -0.0262,-0.0525'
                    id='path28' fill='#221f1f'/>
                <path
                    d='m -96.691739,702.30829 c 0,7.26206 0.1835,13.31815 -3.486841,19.7675 -2.96252,5.24338 -7.65533,8.46805 -12.8987,8.46805 -7.1572,0 -11.32567,-5.45312 -11.32567,-13.50169 0,-15.8874 14.23574,-18.77125 27.711211,-18.77125 l 0,4.03739 m 18.79747,45.43378 c -1.23219,1.10111 -3.01495,1.17976 -4.40444,0.4457 -6.18716,-5.1385 -7.28828,-7.52423 -10.69647,-12.42678 -10.224571,10.4343 -17.460401,13.55409 -30.726141,13.55409 -15.67768,0 -27.89471,-9.67401 -27.89471,-29.04824 0,-15.12713 8.20587,-25.43035 19.87236,-30.46398 10.1197,-4.45688 24.25058,-5.24337 35.051931,-6.47556 l 0,-2.41195 c 0,-4.43066 0.34082,-9.67403 -2.25465,-13.50167 -2.280881,-3.43442 -6.632861,-4.85013 -10.460531,-4.85013 -7.10475,0 -13.44924,3.64414 -14.99603,11.19459 -0.31461,1.67789 -1.5468,3.32955 -3.22467,3.4082 l -18.08962,-1.94006 c -1.52058,-0.3408 -3.19846,-1.57301 -2.77899,-3.90629 4.16848,-21.91728 23.96219,-28.52394 41.68479,-28.52394 9.071011,0 20.921021,2.41195 28.078221,9.28076 9.07104,8.46804 8.20587,19.7675 8.20587,32.06321 l 0,29.04826 c 0,8.73022 3.61794,12.55786 7.02613,17.27691 1.20597,1.67786 1.46814,3.69656 -0.05244,4.95497 -3.80144,3.17225 -10.56538,9.07104 -14.28819,12.37436 l -0.05242,-0.0525'
                    id='path30' fill='#221f1f'/>
                <path
                    d='m 796.12286,646.78101 c 4.48308,0 8.20587,3.61794 8.20587,8.17967 0,4.45685 -3.67035,8.20587 -8.20587,8.20587 -4.50929,0 -8.17965,-3.67037 -8.17965,-8.20587 0,-4.61418 3.72279,-8.17967 8.17965,-8.17967 m 0.0262,-1.57301 c -5.24337,0 -9.77888,4.24713 -9.77888,9.77888 0,5.42689 4.45686,9.75268 9.77888,9.75268 5.34823,0 9.75266,-4.37821 9.75266,-9.75268 0,-5.53175 -4.48307,-9.77888 -9.75266,-9.77888 m -3.67036,15.2582 2.09735,0 0,-4.40443 1.94005,0 c 0.76028,0 0.97002,0.31462 1.12732,0.94381 0,0.1573 0.36704,2.9363 0.39325,3.46062 l 2.3333,0 c -0.28838,-0.52432 -0.44569,-2.01868 -0.55055,-2.91007 -0.20974,-1.38949 -0.31461,-2.35951 -1.80896,-2.46438 0.76028,-0.26217 2.07113,-0.68164 2.07113,-2.70032 0,-2.88387 -2.54304,-2.88387 -3.85388,-2.88387 l -3.74901,0 0,10.95864 m 2.04492,-9.20211 1.75652,0 c 0.57677,0 1.62545,0 1.62545,1.49436 0,0.57677 -0.26217,1.54681 -1.67788,1.54681 l -1.70409,0 0,-3.04117'
                    id='path32' fill='#221f1f'/>
            </g>
        </g>
    </svg>
)

export const EbayIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' className="svg">
        <g transform='matrix(.35 0 0 .35 0 20)'>
            <path id='e'
                  d='m 38.866448,26.308378 c -21.145729,0 -38.76645103,8.97108 -38.76645103,36.036419 0,21.441707 11.84866003,34.944406 39.31245703,34.944406 32.326175,0 34.3984,-21.294248 34.3984,-21.294248 l -15.663557,0 c 0,0 -3.358398,11.466134 -19.690354,11.466134 -13.301932,0 -22.869203,-8.985845 -22.869203,-21.580814 l 59.861133,0 0,-7.903529 c 0,-12.460384 -7.91007,-31.668368 -36.582425,-31.668368 z m -0.546007,10.101117 c 12.662062,0 21.294248,7.757047 21.294248,19.383225 l -43.680508,0 c 0,-12.34261 11.267202,-19.383225 22.38626,-19.383225 z'
                  fill='#e53238'/>
            <path id='b'
                  d='m 75.437762,0.10007279 0,83.59702321 c 0,4.745232 -0.338677,11.408082 -0.338677,11.408082 l 14.939733,0 c 0,0 0.536238,-4.785353 0.536238,-9.1587 0,0 7.381193,11.547477 27.451204,11.547477 21.13453,0 35.49041,-14.673014 35.49041,-35.695165 0,-19.556604 -13.18634,-35.28566 -35.45629,-35.28566 -20.854235,0 -27.33444,11.261381 -27.33444,11.261381 l 0,-37.67443821 z M 114.20421,36.853125 c 14.35199,0 23.47828,10.651661 23.47828,24.945665 0,15.327725 -10.54056,25.35517 -23.3759,25.35517 -15.317854,0 -23.58065,-11.960116 -23.58065,-25.218668 0,-12.354387 7.414449,-25.082167 23.47827,-25.082167 z'
                  fill='#0064d2'/>
            <path id='a'
                  d='m 190.6451,26.308378 c -31.81215,0 -33.85239,17.418776 -33.85239,20.202235 l 15.83418,0 c 0,0 0.83034,-10.169369 16.9262,-10.169369 10.45935,0 18.56422,4.787411 18.56422,13.991413 l 0,3.276038 -18.56422,0 c -24.64532,0 -37.67444,7.20973 -37.67444,21.840254 0,14.398537 12.03849,22.232696 28.30702,22.232696 22.17148,0 29.31371,-12.251017 29.31371,-12.251017 0,4.872784 0.37568,9.67455 0.37568,9.67455 l 14.07643,0 c 0,0 -0.54601,-5.951939 -0.54601,-9.759864 l 0,-32.913945 c 0,-21.581223 -17.40751,-26.122991 -32.76038,-26.122991 z m 17.47221,37.128431 0,4.368051 c 0,5.697129 -3.51553,19.860981 -24.21197,19.860981 -11.3333,0 -16.1925,-5.656156 -16.1925,-12.216892 0,-11.935273 16.36378,-12.01214 40.40447,-12.01214 z'
                  fill='#f5af02'/>
            <path id='y'
                  d='m 214.87901,29.041161 17.81346,0 25.56479,51.217345 25.5063,-51.217345 16.13644,0 -46.45929,91.183029 -16.9262,0 13.40641,-25.418513 z'
                  fill='#86b817'/>
        </g>
    </svg>
)

export const FacebookIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' className="svg" viewBox='0 0 27 26.7'>
        <path fill='#FFF'
              d='M12.4,25.9c0,0,2.6,0.3,4.6-0.5c2-0.8,2-4.6,2-4.6l2.3-5.9L22,9.2l-1.3-4.1c0,0-2.3-0.9-2.7-0.8 s-5.7,0-5.7,0L7,8.7l1.4,7.8l3.4,8.7L12.4,25.9z'
        />
        <path fill='#3B5998'
              d='M16.5,25.5V16h2.7l0.5-3.1h-3.1v-2.7c0-0.9,0.5-1.4,1.4-1.4h2V5.8c0,0-0.9-0.2-2.2-0.2 c-3,0-4.5,1.7-4.5,4.2v3.1h-2.8V16h2.8v9.9C6.4,25.7,0.9,20.2,0.9,13.4c0-6.9,5.6-12.5,12.5-12.5s12.5,5.6,12.5,12.5 C25.9,19.2,21.9,24.1,16.5,25.5z'
        />
    </svg>
)

export const InstagramIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 112.196 112.196' className="svg">
        <circle id='XMLID_115_' cx='56.098' cy='56.097' r='56.098' fill='#6a453b'
        />
        <path d='M79.124,22.506h-46.05c-5.83,0-10.56,4.73-10.56,10.57v46.05c0,5.83,4.73,10.56,10.56,10.56h46.05 c5.83,0,10.56-4.73,10.56-10.56v-46.05C89.684,27.236,84.954,22.506,79.124,22.506z M71.983,30.276l8.44-0.02h0.02 c0.81-0.01,1.47,0.65,1.47,1.45v8.47c0,0.81-0.65,1.46-1.46,1.46l-8.43,0.03c-0.81,0-1.46-0.65-1.46-1.45l-0.03-8.47 C70.523,30.936,71.173,30.286,71.983,30.276z M45.804,50.326c0.22-0.38,0.45-0.76,0.71-1.12c2.15-2.98,5.64-4.93,9.58-4.93 c3.95,0,7.44,1.95,9.59,4.93c0.26,0.36,0.49,0.74,0.71,1.13c0.96,1.7,1.53,3.67,1.53,5.76c0,6.52-5.31,11.82-11.83,11.82 c-6.52,0-11.82-5.3-11.82-11.82C44.273,54.006,44.834,52.036,45.804,50.326z M83.144,76.706c0,3.55-2.89,6.44-6.44,6.44h-41.21 c-3.55,0-6.44-2.89-6.44-6.44v-27.5h10.03c-0.25,0.61-0.47,1.25-0.65,1.89c-0.46,1.59-0.7,3.27-0.7,5 c0,10.13,8.24,18.37,18.36,18.37c10.13,0,18.37-8.24,18.37-18.37c0-1.73-0.25-3.41-0.7-5c-0.19-0.64-0.4-1.28-0.65-1.89h10.03 V76.706z'
              fill='#fff' />
    </svg>
)
